<template>
  <div class="page">
    <Header :loginState="loginState" />
    <div class="account-page">
      <left-menu />
      <div class="account-center">
        <div class="center-box account-information">
          <transaction-nav />
          <div class="title-box">           
            <div class="cfx"></div>
            <p>{{$t('accountCenter.tradeQuery')}}</p>
          </div>
          <div class="account-data flow-main">
            <div class="flow-type">
              <p class="title">{{$t('accountCenter.capitalFlowTitle1')}}</p>
              <button @click="transactionHistory" :class="{active:transactionType==1}">{{$t('accountCenter.tradeHistory')}}</button>
              <button @click="pendingRecord" :class="{active:transactionType==2}">{{$t('accountCenter.pendingOrder')}}</button>
              <button @click="positionRecord" :class="{active:transactionType==3}">{{$t('accountCenter.holdOrder')}}</button>
            </div>
            <div class="flow-type">
              <p class="title">{{$t('accountCenter.capitalFlowTitle3')}}</p>
              <div class="select-box">
                <select v-model="selectValue" @change="selectTime" id="time" class="select-time">
                  <option v-for="(item,index) in dateTimeList" :key="index" :value="item.value">{{item.name}}</option>
                </select>
              </div>             
            </div>
            <div v-show="transactionType == 1" class="total-number">
              <p><span>{{$t('accountCenter.totalVolume')}}</span><span v-if="totalVolume == null">0{{$t('accountCenter.hand')}}</span><span v-else>{{totalVolume}}{{$t('accountCenter.hand')}}</span></p>
              <p><span>{{$t('accountCenter.totalProfit')}}</span><span v-if="totalProfit == null">0</span><span v-else>{{totalProfit}}</span></p>
            </div>  
            <!-- 交易历史 -->
            <div v-if="transactionType == 1" >
              <div class="flow-box scrollbarchange">
                <div class="flow-data history-data">
                  <div class="flow-tlt">
                    <span>{{$t('accountCenter.orderNumber')}}</span><span>{{$t('accountCenter.type')}}</span><span>{{$t('accountCenter.varieties')}}</span><span>{{$t('accountCenter.tradingVolume')}}</span><span>{{$t('accountCenter.openingPrice')}}</span><span>{{$t('accountCenter.openingTime')}}</span>
                    <span>{{$t('accountCenter.closingPrice')}}</span><span>{{$t('accountCenter.closingTime')}}</span><span>{{$t('accountCenter.stopLoss')}}</span><span>{{$t('accountCenter.stopSurplus')}}</span><span>{{$t('accountCenter.overnightInterest')}}</span><span>{{$t('accountCenter.profit')}}</span>
                  </div>
                  <ul class="flow-cont">
                    <p v-if="historyListArray == '' || historyListArray == null" class="null-data">{{$t('accountCenter.dataStatus14')}}</p>
                    <li v-for="(item,index) in historyListArray" :key="index">
                      <span>{{item.ticket}}</span>
                      <span v-if="item.cmd == 0">{{$t('accountCenter.dataStatus1')}}</span>
                      <span v-else-if="item.cmd == 1">{{$t('accountCenter.dataStatus2')}}</span>
                      <span v-else-if="item.cmd == 7">{{$t('accountCenter.dataStatus3')}}</span>
                      <span v-else>{{item.cmd}}</span>
                      <span>{{item.symbol}}</span>
                      <span v-if="item.cmd == 7">{{item.volume}}</span>
                      <span v-else>{{item.volume/100}}</span>
                      <span>{{item.openPrice}}</span><span class="time">{{item.openTime}}</span>
                      <span>{{item.closePrice}}</span><span class="time">{{item.closeTime}}</span>
                      <span>{{item.sl}}</span><span>{{item.tp}}</span>
                      <span>{{item.swaps.toFixed(2)}}</span><span>{{item.profit.toFixed(2)}}</span>
                    </li>
                  </ul>
                </div>
                
              </div>
              <Paging :total="pageConfig.total" :current-page='currentPage' @currentPage="handCurrenPage"/>
            </div>
            
            <!-- 挂单查询 -->
            <div v-if="transactionType == 2">
              <div class="flow-box scrollbarchange">
                <div class="flow-data limit-order-data">
                  <div class="flow-tlt">
                    <span>{{$t('accountCenter.orderNumber')}}</span><span>{{$t('accountCenter.type')}}</span><span>{{$t('accountCenter.varieties')}}</span><span>{{$t('accountCenter.tradingVolume')}}</span><span>{{$t('accountCenter.hangingOrderPrice')}}</span><span>{{$t('accountCenter.hangingOrderTime')}}</span>
                    <span>{{$t('accountCenter.stopLoss')}}</span><span>{{$t('accountCenter.stopSurplus')}}</span>
                  </div>
                  <ul class="flow-cont">
                    <p v-if="limitOrderListArray == '' || limitOrderListArray ==null" class="null-data">{{$t('accountCenter.dataStatus14')}}</p>
                    <li v-for="(item,index) in limitOrderListArray" :key="index">
                      <span>{{item.ticket}}</span>
                      <span v-if="item.cmd == 4">{{$t('accountCenter.dataStatus4')}}</span>
                      <span v-else-if="item.cmd == 2">{{$t('accountCenter.dataStatus5')}}</span>
                      <span v-else-if="item.cmd == 5">{{$t('accountCenter.dataStatus6')}}</span>
                      <span v-else-if="item.cmd == 3">{{$t('accountCenter.dataStatus7')}}</span>
                      <span v-else>{{item.cmd}}</span>
                      <span>{{item.symbol}}</span>
                      <span>{{item.volume/100}}</span>
                      <span>{{item.openPrice}}</span><span>{{item.openTime}}</span>
                      <span>{{item.sl}}</span><span>{{item.tp}}</span>
                    </li>
                  </ul>
                </div>               
              </div>
              <Paging :total="pageConfig.total" :current-page='currentPage' @currentPage="handCurrenPage"/>
            </div>
            
            <!-- 持仓查询 -->
            <div v-if="transactionType == 3">
              <div class="flow-box scrollbarchange">
                <div class="flow-data open-order-data">
                  <div class="flow-tlt">
                    <span>{{$t('accountCenter.orderNumber')}}</span><span>{{$t('accountCenter.type')}}</span><span>{{$t('accountCenter.varieties')}}</span><span>{{$t('accountCenter.tradingVolume')}}</span><span>{{$t('accountCenter.openingPrice')}}</span><span>{{$t('accountCenter.openingTime')}}</span>
                    <span>{{$t('accountCenter.stopLoss')}}</span><span>{{$t('accountCenter.stopSurplus')}}</span><span>{{$t('accountCenter.overnightInterest')}}</span><span>{{$t('accountCenter.profit')}}</span>
                  </div>
                  <ul class="flow-cont">
                    <p v-if="openOrderListArray == '' || openOrderListArray == null" class="null-data">{{$t('accountCenter.dataStatus14')}}</p>
                    <li v-for="(item,index) in openOrderListArray" :key="index">
                      <span>{{item.ticket}}</span>
                      <span v-if="item.cmd == 0">{{$t('accountCenter.dataStatus1')}}</span>
                      <span v-else-if="item.cmd == 1">{{$t('accountCenter.dataStatus2')}}</span>
                      <span v-else>{{item.cmd}}</span>
                      <span>{{item.symbol}}</span>
                      <span>{{item.volume/100}}</span>
                      <span>{{item.openPrice}}</span><span class="time">{{item.openTime}}</span>
                      <span>{{item.sl}}</span><span>{{item.tp}}</span>
                      <span>{{item.swaps.toFixed(2)}}</span><span>{{item.profit.toFixed(2)}}</span>
                    </li>                  
                  </ul>
                </div>               
              </div>
              <Paging :total="pageConfig.total" :current-page='currentPage' @currentPage="handCurrenPage"/>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import getDate from "@/util/getDate";
import Header from "@/components/Header.vue";
import LeftMenu from '@/components/LeftMenu.vue';
import TransactionNav from '@/components/TransactionNav.vue';
import Paging from '@/components/Paging.vue';
import account from '@/api/account.js';
export default {
  name: "TradeHistory",
  components: {
    Header,
    LeftMenu,
    TransactionNav,
    Paging
  },
  data() {
    return {
      loginState:2,
      transactionType:1,
      startTime:"",
      endTime:"",
      currentPage:1,
      pageConfig: {
        total:0,
        pageSize:6,
      },
      totalVolume:0,
      totalProfit:0,
      historyListArray:[],
      limitOrderListArray:[],
      openOrderListArray:[],
      dateTimeList:[
          {
              name: this.$i18n.t('accountCenter.timeSelect1'),
              value:''
          },
          {
              name: this.$i18n.t('accountCenter.timeSelect2'),
              value:''
          },
          {
              name: this.$i18n.t('accountCenter.timeSelect3'),
              value:''
          },
          {
              name: this.$i18n.t('accountCenter.timeSelect4'),
              value:''
          }
      ],
      selectValue:'',
    }
  },
  created() {
    // 近7天
    const nearlySevenDays = getDate.getCurrWeekDays();
    this.dateTimeList[0].value = nearlySevenDays.starttime+"/"+nearlySevenDays.endtime;
    // 默认选中的时间
    this.selectValue = this.dateTimeList[0].value;
    // this.startTime = nearlySevenDays.starttime;
    // this.endTime = nearlySevenDays.endtime;
    var date1 = new Date(nearlySevenDays.starttime);
    var date2 = new Date(nearlySevenDays.endtime);
    this.startTime = Date.parse(date1);
    this.endTime  = Date.parse(date2);
    console.log(this.selectValue)
    // 本月
    const thisMonth = getDate.getCurrMonthDays();
    this.dateTimeList[1].value = thisMonth.starttime+"/"+thisMonth.endtime;
    // 上个月
    const lastMonth = getDate.getLastMonthDays();
    this.dateTimeList[2].value = lastMonth.starttime+"/"+lastMonth.endtime;
    // 近一年
    const nearlyYear = getDate.getCurrYearDays();
    this.dateTimeList[3].value = nearlyYear.starttime+"/"+nearlyYear.endtime;

    // 默认状态显示的入金流水
    this.historyOrder(this.startTime,this.endTime,this.pageConfig.pageSize,this.currentPage);
    console.log(this.pageConfig.pageSize)
  },
  
  methods: {
    // 交易历史
    historyOrder(startTime,endTime,pageSize,currentPage){
      let data = {
          beginTime: startTime,
          endTime: endTime,
          pageSize: pageSize,
          currentPage:currentPage,
        }
      account.queryHistoryOrder(data).then((res)=>{
        if(res.data.success == 1){
              this.pageConfig.total = res.data.value.total;
              this.pageConfig = JSON.parse(JSON.stringify(this.pageConfig));
              this.currentPage = res.data.value.currentPage;
              let array = res.data.value.resultList;
              for (let i = 0; i < array.length; i++) {
                let openTime = array[i].openTime;
                let closeTime = array[i].closeTime;
                array[i].openTime =  this.timestampToTime(openTime);
                array[i].closeTime =  this.timestampToTime(closeTime);
              }
              this.historyListArray = array;
              this.totalVolume = res.data.value.volume;
              this.totalProfit = res.data.value.profit;
          }else{
              console.log(res.data.message);
          }
      }).catch(err=>{
        console.log(err.message);
      })
    },
    // 挂单查询
    limitOrder(startTime,endTime,pageSize,currentPage){
      let data = {
          startTime: startTime,
          endTime: endTime,
          pageSize:pageSize,
          currentPage:currentPage,
        }
      account.queryLimitOrder(data).then((res)=>{
        if(res.data.success == 1){
              this.pageConfig.total = res.data.value.total;
              this.pageConfig = JSON.parse(JSON.stringify(this.pageConfig));
              this.currentPage = res.data.value.currentPage;
              let array = res.data.value.resultList
              for (let i = 0; i < array.length; i++) {
                let openTime = array[i].openTime;              
                array[i].openTime =  this.timestampToTime(openTime);               
              }
              this.limitOrderListArray = array;
          }else{
              console.log(res.data.message);
          }
      }).catch(err=>{
        console.log(err.message);
      })
    },

    // 持仓查询
    openOrder(startTime,endTime,pageSize,currentPage){
      let data = {
          startTime: startTime,
          endTime: endTime,
          pageSize:pageSize,
          currentPage:currentPage,
        }
      account.queryOpenOrder(data).then((res)=>{
        if(res.data.success == 1){
              this.pageConfig.total = res.data.value.total;
              this.pageConfig = JSON.parse(JSON.stringify(this.pageConfig));
              this.currentPage = res.data.value.currentPage;
              let array = res.data.value.resultList
              for (let i = 0; i < array.length; i++) {
                let openTime = array[i].openTime;              
                array[i].openTime =  this.timestampToTime(openTime);               
              }
              this.openOrderListArray = array;
          }else{
              console.log(res.data.message);
          }
      }).catch(err=>{
        console.log(err.message);
      })
    },
    // 将时间戳转换为日期格式
    timestampToTime(timestamp) {
        var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        var D = (date.getDate() < 10 ? '0'+ date.getDate(): date.getDate()) + ' ';
        var h = (date.getHours() < 10 ? '0'+ date.getHours(): date.getHours()) + ':';
        var m = (date.getMinutes() < 10 ? '0'+ date.getMinutes(): date.getMinutes()) + ':';
        var s = (date.getSeconds() < 10 ? '0'+ date.getSeconds(): date.getSeconds());
        return Y+M+D+h+m+s;
    },
    // 交易历史
    transactionHistory(){
      this.transactionType = 1
      this.historyOrder(this.startTime,this.endTime,this.pageConfig.pageSize,this.currentPage);
    },
    // 挂单查询
    pendingRecord(){
      this.transactionType = 2
      this.limitOrder(this.startTime,this.endTime,this.pageConfig.pageSize,this.currentPage);
    },
    // 持仓查询
    positionRecord(){
      this.transactionType = 3
      this.openOrder(this.startTime,this.endTime,this.pageConfig.pageSize,this.currentPage);
    },

    // 时间下拉选中方法
    selectTime(){
      var timeAttr = [];
      timeAttr=this.selectValue.split("/");
      // this.startTime = timeAttr[0];
      // this.endTime = timeAttr[1];
      var date1 = new Date(timeAttr[0]);
      var date2 = new Date(timeAttr[1]);
      this.startTime = Date.parse(date1);
      this.endTime  = Date.parse(date2);
      if(this.transactionType == 1){       
        this.historyOrder(this.startTime,this.endTime,this.pageConfig.pageSize,this.currentPage);
      }
      if(this.transactionType == 2){       
        this.limitOrder(this.startTime,this.endTime,this.pageConfig.pageSize,this.currentPage);
      }
      if(this.transactionType == 3){       
        this.openOrder(this.startTime,this.endTime,this.pageConfig.pageSize,this.currentPage);
      }
    },
    
    // 分页调用方法
    handCurrenPage(val){
    　　this.currentPage = val
    if(this.transactionType == 1){       
        this.historyOrder(this.startTime,this.endTime,this.pageConfig.pageSize,this.currentPage);
      }
      if(this.transactionType == 2){       
        this.limitOrder(this.startTime,this.endTime,this.pageConfig.pageSize,this.currentPage);
      }
      if(this.transactionType == 3){       
        this.openOrder(this.startTime,this.endTime,this.pageConfig.pageSize,this.currentPage);
      }
    }
    
  },
};
</script>
<style>
@import url("../assets/css/public.css");
</style>
